import { setGlobalRequestHeader } from '@virgodev/bazaar/functions/api';
import LogRocket from 'logrocket';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import globalMethods from './methods';

LogRocket.init('ouynfn/uml-prod');

if (store.state.userData !== null) {
  setGlobalRequestHeader('Authorization', `Token ${store.state.userData.drf_token}`);
}

Vue.config.productionTip = false;

Vue.use(VueMeta);

Vue.mixin({
  computed: {
    userData() { return this.$store.state.userData; },
  },
  methods: globalMethods,
});

window.app = new (Vue.extend(App))({
  router,
  store,
  vuetify,
}).$mount('#app');
