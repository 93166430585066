export default function formatCurrency(number) {
  const formatted = new Intl.NumberFormat(
    'en-us',
    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  ).format(number);

  if (formatted.endsWith('.00')) {
    return formatted.slice(0, -3);
  }

  return formatted;
}
