import api, { addResponseHandler } from '@virgodev/bazaar/functions/api';

import capitalizeFirstLetter from './capitalize_first_letter';
import formatCurrency from './format_currency';
import formatNumber from './format_number';
import isObjectEmpty from './is_object_empty';
import logUserOut from './log_user_out';
import scrollToFirstError from './scroll_to_first_error';
import toListSentence from './to_list_sentence';

addResponseHandler(401, logUserOut);
addResponseHandler(403, (response, responseBody) => {
  if (responseBody.detail === 'An active subscription is required.') {
    if (window.app.$router.currentRoute.name !== 'subscription') {
      window.app.$router.replace({ name: 'subscription' });
    }
  } else {
    logUserOut();
  }
});

export default {
  api,
  capitalizeFirstLetter,
  formatCurrency,
  formatNumber,
  isObjectEmpty,
  logUserOut,
  scrollToFirstError,
  toListSentence,
};
