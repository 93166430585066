import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/search/:savedSearchId?',
    name: 'search',
    component: () => import('../views/Search.vue'),
    props(route) {
      const props = { ...route.params };

      if (props.savedSearchId !== undefined) {
        if (/^\d+$/.test(props.savedSearchId)) {
          props.savedSearchId = +props.savedSearchId;
        } else {
          props.savedSearchId = 0;
        }
      }

      return props;
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/password_reset',
    name: 'password_reset',
    meta: { allowAnonymous: true },
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/password_reset/:uidb64/:token/',
    name: 'password_reset_confirm',
    meta: { allowAnonymous: true },
    props: true,
    component: () => import('../views/PasswordResetConfirm.vue'),
  },
  {
    path: '/sign_up',
    name: 'sign_up',
    meta: { allowAnonymous: true },
    component: () => import('../views/SignUp.vue'),
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('../views/Subscription.vue'),
  },
  {
    path: '/edit_profile',
    name: 'edit_profile',
    component: () => import('../views/EditProfile.vue'),
  },
  {
    path: '*',
    name: 'page_not_found',
    meta: { allowAnonymous: true },
    component: () => import('../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next();
  } else if (store.state.userData === null) {
    next({
      name: 'login',
    });
  } else {
    next();
  }
});

export default router;
