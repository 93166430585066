export default function scrollToFirstError() {
  const element = document.documentElement;
  const elementToScroll = window;

  const firstError = element.querySelector('.warning, .error--text');

  if (firstError) {
    const domRect = firstError.getBoundingClientRect();
    elementToScroll.scrollTo({
      top: domRect.top + element.scrollTop - 95,
      left: domRect.left + element.scrollLeft,
      behavior: 'smooth',
    });
  }
}
