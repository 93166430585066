<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title>
        <a href="/">
          <img
            src="@/assets/logo.svg"
            alt="Unlimited Mailing Lists"
          >
        </a>
      </v-toolbar-title>

      <v-spacer />

      <template v-if="userData">
        <v-btn
          :to="{ name: 'home' }"
          exact
          class="mr-2"
          text
        >
          Dashboard
        </v-btn>

        <v-btn
          :to="{ name: 'search' }"
          class="mr-2"
          text
        >
          Search
        </v-btn>

        <v-btn
          href="/faq/"
          class="mr-2"
          text
        >
          FAQ
        </v-btn>

        <v-btn
          href="/user-contact/"
          class="mr-2"
          text
        >
          Contact
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              Account
            </v-btn>
          </template>
          <v-list>
            <v-subheader>{{ userData.email }}</v-subheader>
            <v-list-item :to="{ name: 'edit_profile' }">
              <v-list-item-title>Edit Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="confirmLogOut">
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-else>
        <v-btn
          :to="{ name: 'login' }"
          class="mr-2"
          text
        >
          Log In
        </v-btn>

        <v-btn
          :to="{ name: 'sign_up' }"
          text
        >
          Subscribe
        </v-btn>
      </template>
    </v-app-bar>

    <div id="before-main" />

    <v-main>
      <v-alert
        dense
        border="left"
        type="info"
      >
        We are closing our website on August 18, 2023.
      </v-alert>

      <v-dialog
        v-model="closingSiteDialogShowing"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Website Closure
          </v-card-title>

          <v-card-text>We are closing our website on August 18, 2023</v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              @click="closingSiteDialogShowing = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view :key="`routerView${routerViewKeySuffix}`" />
    </v-main>

    <v-footer>
      <div class="footer-content">
        Copyright {{ new Date().getFullYear() }} - UnlimitedMailingLists™ UML-JB, Inc.
        <span>PO Box 126468 Fort Worth, TX 76126</span>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  data: () => ({
    closingSiteDialogShowing: false,
  }),
  computed: {
    ...mapState([
      'routerViewKeySuffix',
      'closingSiteDialogShown',
    ]),
  },
  metaInfo: {
    title: 'Unlimited Mailing Lists',
  },
  created() {
    if (!this.closingSiteDialogShown) {
      this.closingSiteDialogShowing = true;
      this.$store.commit('closingSiteDialogShown');
    }
  },
  methods: {
    confirmLogOut() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to log out?')) {
        this.logUserOut('redirect');
      }
    },
  },
};
</script>

<style lang="scss">
  .subscription-plan {
    width: 100%;
  }

  .subscription-plan-selected {
    color: #fff !important;
  }

  .subscription-plan-name {
    font-size: 120%;
    font-weight: bold;
  }

  .subscription-plan-price {
    font-size: 110%;
  }

  .search-criteria-table {
    th,
    td {
      vertical-align: top;
    }

    th {
      padding-right: 8px;
      width: 10.7em;
      text-align: right;
    }

    td {
      word-break: break-word;
    }
  }

  @media (max-width: 567.98px) {
    .search-criteria-table th {
      width: 7.2em;
    }
  }
</style>

<style lang="scss" scoped>
  .v-toolbar__title {
    line-height: 1;

    img {
      height: 30px;
      vertical-align: middle;
    }
  }

  .footer-content {
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 767.98px) {
    .v-app-bar,
    ::v-deep .v-toolbar__content {
      height: 80px !important;
    }

    ::v-deep .v-toolbar__content {
      flex-wrap: wrap;
      justify-content: center;
    }

    .v-toolbar__title {
      width: 100%;
      text-align: center;
    }

    .v-content {
      padding-top: 80px !important;
    }

    .footer-content {
      font-size: 15px;
    }
  }

  @media (max-width: 666.98px) {
    .footer-content span {
      display: block;
    }
  }

  @media (max-width: 567.98px) {
    .v-toolbar__content .v-btn {
      padding: 0 4px !important;
      letter-spacing: 0;
    }

    .footer-content {
      font-size: 14px;
    }
  }

  @media (max-width: 479.98px) {
    ::v-deep .v-toolbar__content {
      padding-right: 2px;
      padding-left: 2px;

      .v-btn {
        font-size: 0.75rem;
      }
    }
  }

  @media (max-width: 374.98px) {
    .v-toolbar__content .v-btn {
      min-width: 0 !important;
      font-size: 0.7rem;
    }

    .footer-content {
      font-size: 12px;
    }
  }

  @media (max-width: 319.98px) {
    .v-toolbar__content .v-btn {
      padding: 0 1px !important;
    }
  }

  @media (max-width: 287.98px) {
    .v-toolbar__content .v-btn {
      font-size: 0.57rem;
    }
  }
</style>
