import { setGlobalRequestHeader } from '@virgodev/bazaar/functions/api';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routerViewKeySuffix: 0,
    userData: JSON.parse(localStorage.getItem('userData')),
    closingSiteDialogShown: JSON.parse(localStorage.getItem('closingSiteDialogShown') || 'false'),
  },
  mutations: {
    routerViewKeySuffixIncrement(state) {
      state.routerViewKeySuffix += 1;
    },
    userData(state, value) {
      state.userData = value;
      localStorage.setItem('userData', JSON.stringify(value));

      if (value) {
        setGlobalRequestHeader('Authorization', `Token ${value.drf_token}`);
      } else {
        setGlobalRequestHeader('Authorization', undefined);
      }
    },
    closingSiteDialogShown(state) {
      state.closingSiteDialogShown = true;
      localStorage.setItem('closingSiteDialogShown', 'true');
    },
  },
});
